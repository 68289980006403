<template>
  <!-- Contents -->
  <main id="content" role="main">
    <section class="news_group">
        <div class="news_title">
            <div class="title_info">
                <span class="info category" v-text="contentsDetail.contentCategoryName"></span>
                <span class="info update" v-text="datafilter(contentsDetail.insertDate)"></span>
            </div>
            <strong class="title" v-text="contentsDetail.title"></strong>
        </div>
        <!-- <div class="news_article" ref="contentEditor1" v-html="this.contentsDetail.description"> </div> -->
        <div class="news_article2">
          <TinyViewr :description="contentsDetail.description" ></TinyViewr>
        </div>
        <div class="news_share">
          <button class="share_link" @click="kakaoLink(contentsDetail.title,img)">
            <div class="ico_kakao">카카오 공유</div></button>
          <button class="share_link" @click="facebookLink"><div class="ico_facebook">페이스북 공유</div></button>
          <button class="share_link" @click="copyLink"><div class="ico_link">링크 공유</div></button>
          <button class="share_link" @click="fileDown" v-show="fileBoolean"><div class="ico_disk">파일 다운</div></button>
        </div>

        <div class="news_more">
          <router-link :to="{ name: 'ContentsList' }" class="btn_list">
            <em class="txt" v-html="$t('content.news.detail.list_view')">
            </em>
          </router-link>
        </div>
    </section>

    <section class="report_group">
        <div class="recommend_tab" role="tablist">
            <button role="tab" class="tab" :aria-selected="!range" @click="tabContents('pipe')"
            v-html="$t('content.news.detail.contents.related')">
            </button>
            <button role="tab" class="tab" :aria-selected="range" @click="tabContents('popularity')"
            v-html="$t('content.news.detail.contents.popular')">
            </button>
        </div>
        <div class="news_swiper">
          <div class="news_list">
            <GroupedSlider
              ref="swiperSlider"
              :pagination="true"
              :list="contentslist"
              :autoplay="false"
              :loop="false"
              :itemsPerGroup="3"
            >
            <template v-slot="{ item }">
              <div class="news_item">
                <button @click="reset(item.id)" class="news">
                  <div class="thumb" >
                    <template v-if="item.youtubeUrl">
                      <img :src="`https://img.youtube.com/vi/${youtubeUrl(item.youtubeUrl)}/0.jpg`"
                        alt="" class="img_thumb" data-aos="fade-up">
                    </template>
                    <template v-else-if="item.thumbnailFileUrl">
                      <img
                          data-aos="fade-up"
                          :src="item.thumbnailFileUrl"
                          alt="" class="img_thumb">
                    </template>
                  </div>
                  <strong class="category" v-text="item.contentCategoryName"></strong>
                  <strong class="title" v-text="item.title"></strong>
                  <div class="update" v-text="item.insertDate"></div>
                </button>
              </div>
            </template>
            </GroupedSlider>
          </div>
        </div>
    </section>
  </main>

  <!-- // CONTENTS -->
</template>

<script>
import { mixin as mixinHelperUtils } from '@/common/helperUtils';
import GroupedSlider from '@/components/slider/NewGroupedSlider.vue';
import { APIs, FilePaths } from '@/common/portalApi';
import JSZipUtils from '@/common/jszip-utils.min';
import JSZip from '@/common/jszip.min';
import FileSaver from '@/common/FileSaver.min';
import TinyViewr from '@/components/base/TinyViewer.vue';
import MobileDetect from 'mobile-detect';

export default {
  name: 'ContentsDetail',
  mixins: [
    mixinHelperUtils,
  ],
  components: {
    GroupedSlider,
    TinyViewr
  },
  data() {
    return {
      filePaths: FilePaths,
      helperUtils: mixinHelperUtils.methods,
      contentsDetail: {},
      contentslist: [],
      range: false,
      copy: '',
      fileBoolean: false,
      ncNoData: 0
    };
  },
  mounted() {
    this.retrieveContents();
  },
  computed: {
    isMobile() {
      const md = new MobileDetect(window.navigator.userAgent);
      return !!!(md.mobile() === null);
    }
  },
  methods: {
    // 뉴스 상세 조회
    async retrieveContents(newncNo) {
      const { ncNo } = newncNo === undefined ? this.$route.params : { ncNo: newncNo };
      this.ncNoData = ncNo;
      this.portalApiClient({
        url: `${APIs.CONTENTS_DETAIL}/${ncNo}`,
        method: 'get',
      }).then(({ data }) => {
        if (data.result) {
          this.contentsDetail = data.data;
          this.imgGet();
          if (!this.range) {
            this.pipeList(this.contentsDetail.id);
          }
          if (this.contentsDetail.fileList?.length > 0) {
            this.fileBoolean = true;
          }

          if (this.isMobile) {
            if (this.contentsDetail.description) {
              this.contentsDetail.description = this.mobileIframe(this.contentsDetail.description)
            }
          }
        } else {
          this.showAlert(data.message);
        }
      });
    },
    youtubeUrl(url) {
      // eslint-disable-next-line
      const match = url.match(/^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/);
      // console.log(match[7]);
      return match === null ? '' : match[7];
    },
    imgGet() {
      if (this.contentsDetail.youtubeUrl) {
        this.img = `https://img.youtube.com/vi/${this.youtubeUrl(this.contentsDetail.youtubeUrl)}/0.jpg`;
      } else if (this.contentsDetail.thumbnailFileUrl) {
        this.img = this.contentsDetail.thumbnailFileUrl;
      }
    },
    tabContents(tab) {
      switch (tab) {
        case 'pipe':
          this.range = false;
          this.$refs.swiperSlider.$el.swiper.slideTo(0);
          this.pipeList(this.ncNoData);
          break;
        case 'popularity':
          this.range = true;
          this.$refs.swiperSlider.$el.swiper.slideTo(0);
          this.popularityList();
          break;
        default:
          break;
      }
    },
    pipeList(ctId) {
      this.portalApiClient({
        url: APIs.CONTENTS_LIST,
        data: {
          data: {
            contentCategory: this.contentsDetail.contentCategory,
            isTmp: 0,
            isShow: 1,
            order: 'content.id desc'
          },
          page: 1,
          size: 4
        }
      }).then(({ data }) => {
        this.contentslist = data.list.filter((v)=>{
          return v.id != ctId
        }).slice(0, 3);
      });
    },
    popularityList() {
      this.portalApiClient({
        url: APIs.CONTENTS_LIST,
        data: {
          data: {
            // contentCategory: this.contentsDetail.contentCategory,
            isTmp: 0,
            sShow: 1,
            order: 'content.hit_count desc'
          },
          page: 1,
          size: 4
        }
      }).then(({ data }) => {
        this.contentslist = data.list.slice(0, 3);
        this.contentslist = data.list.filter((v)=>{
          return v.id != this.contentsDetail.id
        }).slice(0, 3);
      });
    },
    datafilter(date) {
      return this.$moment(date).format('YYYY.MM.DD');
    },
    reset(newncNo) {
      this.scrollTo(0, 1, 0); // mixinHelperUtils
      this.retrieveContents(newncNo);
    },
    downloadAttachFile(attachFilePath, displayFileName) {
      const fileURL = this.getFileURL(FilePaths.CONTENT, attachFilePath);
      this.downloadFile(fileURL, displayFileName);
    },
    back() {
      // 히스토리 기능 추가 구현 필요.
      // this.$router.go(-1);
    },
    getBinaryContent (zip, fileURL, fileName) {
      return new Promise((resolve, reject)=>{
        JSZipUtils.getBinaryContent(fileURL, (err, data) => {
            if (err) {
              reject(err);
            }
            zip.file(fileName.normalize('NFC'), data, { binary: true });
            console.log('resolve', fileName);
            resolve();
        });
      });
    },
    async fileDown() {
      const fileObj = this.contentsDetail.fileList;
      if (fileObj.length === 1) {
        const fileURL = fileObj[0].url;
        this.downloadFileV2(fileURL, fileObj[0].originalName);
      } else {
        const zip = new JSZip();
        for (let i = 0; i < fileObj.length; i++) {
          const v = fileObj[i];
          const fileURL = v.url;
          await this.getBinaryContent(zip, fileURL, v.originalName);
        }
        zip.generateAsync({
          type: 'blob',
        })
        .then((content) => {
          // console.log(content);
          FileSaver.saveAs(content, 'contents.zip');
        });
      }
    },
    mobileIframe(str) {
      let regex = /<p[^>]*>(<iframe*[^>]+(width=*[^height]*height="[0-9]*")[^>]*><\/iframe><\/p>)/g;
      const regexp = new RegExp(regex, "gi");
      const matchedWord = str.match(regexp);
      for (const word of matchedWord) {
        str = str.replace(word, '<div class=video-container>' + word.replace(/width=*[^height]*height="[0-9]*"/g, '') + '</div>')
      }
      return str;
    }
  },
};
</script>

<style>
.news_article2 iframe {width:100%;height:540px;margin:30px auto;}
.news_article2 img {max-width:950px;}
.news_article2 p {margin-top:13px;margin-bottom:13px;}
.news_article2 h1, h2, h3, h4, h5, h6 {margin-top:13px;margin-bottom:13px;}

.news_article2 {
  padding: 60px 0 80px;
  font-size: 16px;
  line-height: 1.3;
  color: #666;
  overflow: hidden;
}
.news_article2 { margin-top: 2.8rem; overflow: hidden; color: #666; font-size: 1.5rem; line-height: 1.7; }
.news_article2 img { max-width: 100% !important; height: auto !important; }
.news_article2 iframe { height: 300px; margin: 0 !important; min-height: 32rem; }
.news_article2 .ql-editor { padding-left: 2.4rem; padding-right: 2.4rem; }
.news_detail .news_article2 { padding-left: 2.4rem; padding-right: 2.4rem; }
</style>
